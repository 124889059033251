import lodash from 'lodash';
export var init = {
    params: {
        pref: '000000',
        count: 25,
        pict_size: 5,
        picts: 3,
        h_type: 1,
        order: 4
    }
};
export var RefineAction = {
    INIT: 'REFINE_INIT',
    SET: 'REFINE_SET',
    MULTI_SET: 'REFINE_MULTI_SET',
    REMOVE: 'REFINE_REMOVE'
};
export default function reducer(state, action) {
    if (state === void 0) { state = lodash.cloneDeep(init); }
    switch (action.type) {
        case RefineAction.INIT:
            return lodash.cloneDeep(init);
        case RefineAction.SET:
            var _a = action.payload, newParam = _a.newParam, value = _a.value;
            state.params[newParam] = value;
            return lodash.cloneDeep(state);
        case RefineAction.MULTI_SET:
            var _b = action.payload, newParams = _b.newParams, values_1 = _b.values;
            newParams.forEach(function (v, k) { return state.params[v] = values_1[k]; });
            return lodash.cloneDeep(state);
        case RefineAction.REMOVE:
            var keys = Object.keys(state.params);
            if (keys.includes(action.payload)) {
                delete state.params[action.payload];
                return lodash.cloneDeep(state);
            }
        default:
            return state;
    }
}
export var action;
(function (action) {
    action.init = function () { return ({
        type: RefineAction.INIT
    }); };
    action.set = function (newParam, value) { return ({
        type: RefineAction.SET,
        payload: {
            newParam: newParam,
            value: value
        }
    }); };
    action.multiSet = function (newParams, values) { return ({
        type: RefineAction.MULTI_SET,
        payload: {
            newParams: newParams,
            values: values
        }
    }); };
    action.remove = function (param) { return ({
        type: RefineAction.REMOVE,
        payload: param
    }); };
})(action || (action = {}));
