import React from 'react';
import './HamburgerMenu.sass';
var HamburgerMenu = function (props) { return (React.createElement("div", { className: 'HamburgerMenu' },
    React.createElement("div", { className: props.isClicked === false
            ? "HamburgerMenu__bar"
            : "HamburgerMenu__bar--cross" }),
    React.createElement("div", { className: props.isClicked === false
            ? "HamburgerMenu__bar"
            : "HamburgerMenu__bar--cross" }))); };
export default HamburgerMenu;
