import React, { useEffect, useContext } from 'react';
import { ReactGAContext } from '../../Router';
var GoogleAnalytics = function () {
    var GA = useContext(ReactGAContext);
    useEffect(function () {
        if (process.env.NODE_ENV === 'production') {
            GA.pageview(window.location.pathname + window.location.search);
        }
    }, []);
    return (React.createElement(React.Fragment, null));
};
export default GoogleAnalytics;
