import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
var RedirectToHome = function (props) {
    var refine = useSelector(function (state) { return state.refine; });
    var history = useHistory();
    var firstLoad = useRef(true);
    if (props.isLocation) {
        useEffect(function () {
            if (firstLoad.current === true) {
                firstLoad.current = false;
                return;
            }
            history.push('/');
        }, [refine.params.pref]);
    }
    else {
        useEffect(function () {
            if (firstLoad.current === true) {
                firstLoad.current = false;
                return;
            }
            history.push('/');
        }, [refine]);
    }
    return (React.createElement(React.Fragment, null));
};
export default RedirectToHome;
