function putTogetherSimilarPlans(Plan) {
    var r = {};
    Plan.forEach(function (v) {
        if (v.PlanName in r) {
            var isMoreCheap = (parseInt(v.PlanSampleRateFrom) < r[v.PlanName].PlanSampleRateFrom);
            if (isMoreCheap) {
                r[v.PlanName] = v;
            }
        }
        else {
            r[v.PlanName] = v;
        }
    });
    return Object.values(r);
}
export default function initDetailInformation(detail) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var init = {
        PostCode: ((_a = detail) === null || _a === void 0 ? void 0 : _a.PostCode) || '',
        HotelAddress: ((_b = detail) === null || _b === void 0 ? void 0 : _b.HotelAddress) || '',
        PictureURL: [],
        Rating: ((_c = detail) === null || _c === void 0 ? void 0 : _c.Rating) || '',
        HotelName: ((_d = detail) === null || _d === void 0 ? void 0 : _d.HotelName) || '',
        HotelCatchCopy: ((_e = detail) === null || _e === void 0 ? void 0 : _e.HotelCatchCopy) || '',
        HotelCaption: ((_f = detail) === null || _f === void 0 ? void 0 : _f.HotelCaption.replace(/&lt;BR&gt;/g, '<br />')) || '',
        Plan: []
    };
    if (!detail)
        return init;
    /* PictureURL is string or string[] when get from jalan api */
    if (detail.PictureURL.length > 0) {
        init.PictureURL = typeof detail.PictureURL === 'string'
            ? [detail.PictureURL]
            : Array.from(detail.PictureURL);
    }
    /* Plan is any or any[] when get from jalan api */
    var Plan = ((_g = detail) === null || _g === void 0 ? void 0 : _g.Plan) ? ((_h = detail.Plan) === null || _h === void 0 ? void 0 : _h.PlanPictureURL) === undefined ? Array.from(detail.Plan) : [detail.Plan]
        : [];
    /* exclude if not have picture */
    Plan = Plan.filter(function (v) { var _a; return (_a = v) === null || _a === void 0 ? void 0 : _a.PlanPictureURL; });
    /* when get plans from jalan, there are many similar */
    Plan = putTogetherSimilarPlans(Plan);
    init.Plan = Plan.map(function (v) { return ({
        images: typeof v.PlanPictureURL === 'string'
            ? [v.PlanPictureURL]
            : Array.from(v.PlanPictureURL),
        name: v.PlanName,
        rate: v.PlanSampleRateFrom,
        url: v.PlanDetailURL.replace(/&amp;/g, '&')
    }); });
    return init;
}
