var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect, useRef } from 'react';
import InstagramCarousel from 'react-instagram-carousel';
import Loading from './Loading';
import MapMarkarIcon from '../atoms/map-markar.png';
import DetailHeader from './DetailHeader';
import Stars from '../molecules/Stars';
import DetailPlanCarousel from './DetailPlanCarousel';
import './Detail.sass';
import './Detail.media.sass';
var Detail = function (props) {
    var className = {
        normal: 'Detail',
        hidden: 'Detail Detail--hidden',
        translate: 'Detail Detail--translate'
    };
    var _a = __read(useState(className.hidden), 2), detailClassName = _a[0], setDetailClassName = _a[1];
    var isFirstLoad = useRef(true);
    useEffect(function () {
        if (isFirstLoad.current)
            return;
        if (props.visible) {
            setDetailClassName(className.normal);
        }
        else {
            setDetailClassName(className.translate);
            setTimeout(function () { return setDetailClassName(className.hidden); }, 300);
        }
    }, [props.visible]);
    useEffect(function () {
        isFirstLoad.current = false;
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(DetailHeader, { visible: props.visible, onClose: function () {
                var _a;
                props.setVisible(false);
                ((_a = props) === null || _a === void 0 ? void 0 : _a.onClose) && props.onClose();
            } }),
        React.createElement("aside", { className: detailClassName },
            React.createElement("div", { className: props.loading
                    ? 'Detail__loading'
                    : 'Detail__loading Detail__loading--hidden' },
                React.createElement(Loading, { visible: true })),
            React.createElement("div", { className: "Detail__carousel" },
                React.createElement(InstagramCarousel, { images: props.detail.PictureURL, nextMsec: 3000, barHeight: 2 })),
            React.createElement("div", { className: "Detail__stars" },
                React.createElement(Stars, { rating: props.detail.Rating, favoriteSelected: props.favoriteSelected, toggleFavorite: props.toggleFavorite })),
            React.createElement("div", { className: "Detail__name" }, props.detail.HotelName),
            React.createElement("div", { className: "Detail__catchCopy" }, props.detail.HotelCatchCopy),
            React.createElement("div", { className: "Detail__introduction" },
                React.createElement("div", { className: "Detail__caption", dangerouslySetInnerHTML: {
                        __html: props.detail.HotelCaption
                    } }),
                React.createElement("a", { className: "Detail__street", target: "_blank", href: 'https://www.google.com/maps/search/?api=1&query='
                        + props.detail.HotelAddress.split(' ').join('+') },
                    React.createElement("img", { src: MapMarkarIcon }),
                    "\u3012",
                    props.detail.PostCode,
                    " ",
                    props.detail.HotelAddress)),
            React.createElement("div", { className: "Detail__plans" }, props.detail.Plan.map(function (v, k) { return (React.createElement("div", { className: "Detail__planCard", key: k },
                React.createElement(DetailPlanCarousel, __assign({}, v)))); })))));
};
export default Detail;
