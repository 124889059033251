import React from 'react';
import RedirectToHome from './lib/RedirectToHome';
import CreateSEOTag from './lib/CreateSEOTag';
import GoogleAnalytics from './lib/GoogleAnalytics';
import { default as Template } from '../components/templates/NotFound';
var NotFound = function () { return (React.createElement(React.Fragment, null,
    React.createElement(RedirectToHome, null),
    React.createElement(CreateSEOTag, { title: "\u304A\u63A2\u3057\u306E\u30DA\u30FC\u30B8\u306F\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F", description: "404 Not Found. \u304A\u63A2\u3057\u306E\u30DA\u30FC\u30B8\u306F\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002", noIndex: true }),
    React.createElement(GoogleAnalytics, null),
    React.createElement(Template, null))); };
export default NotFound;
