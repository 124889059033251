import '@babel/polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Router';
import { applyMiddleware, createStore } from 'redux';
import { save, load } from 'redux-localstorage-simple';
import { Provider } from 'react-redux';
import Redurces from './modules';
var syncStore = {
    states: ['refine', 'favorite', 'playStoreLink'],
    namespace: 'furrato-storage',
    disableWarnings: true
};
var createStoreWithMiddleWare = applyMiddleware(save(syncStore))(createStore);
var store = createStoreWithMiddleWare(Redurces, load(syncStore));
ReactDOM.render((React.createElement(Provider, { store: store },
    React.createElement(Router, null))), document.getElementById('root'));
