var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { action as playStoreLinkAction } from '../../../../modules/playStoreLink';
import NormalLayout from '../NormalLayout';
var NormalLayoutContainer = function (props) {
    var mapState = useSelector(function (state) { return state; }, shallowEqual);
    var dispatch = useDispatch();
    var mapDispatch = ({
        playStoreLink: bindActionCreators(playStoreLinkAction, dispatch),
    });
    return (React.createElement(NormalLayout, __assign({ store: __assign(__assign({}, mapState), { dispatch: mapDispatch }) }, props)));
};
export default NormalLayoutContainer;
