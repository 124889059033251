import React from 'react';
import RedirectToHome from './lib/RedirectToHome';
import CreateSEOTag from './lib/CreateSEOTag';
import GoogleAnalytics from './lib/GoogleAnalytics';
import policyText from '../components/atoms/policy';
import { default as Template } from '../components/templates/Letter';
var Policy = function () { return (React.createElement(React.Fragment, null,
    React.createElement(RedirectToHome, null),
    React.createElement(CreateSEOTag, { title: "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC", description: "furrato\u306E\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC\u306E\u30DA\u30FC\u30B8\u3067\u3059\u3002" }),
    React.createElement(GoogleAnalytics, null),
    React.createElement(Template, { title: 'プライバシーポリシー', text: policyText }))); };
export default Policy;
