export function convertToJpnGeo(_a) {
    var latitude = _a.latitude, longitude = _a.longitude;
    return {
        latitude: latitude * 1.000106961 - longitude * 0.000017467 - 0.004602017,
        longitude: longitude * 1.000083049 + latitude * 0.000046047 - 0.010041046
    };
}
export default function converToJaranAPICoords(position) {
    var _a = convertToJpnGeo(position), latitude = _a.latitude, longitude = _a.longitude;
    return {
        latitude: Math.floor(latitude * 3600 * 1000),
        longitude: Math.floor(longitude * 3600 * 1000)
    };
}
export function getGPSError(e) {
    switch (e.code) {
        case 1: //PERMISSION_DENIED
            alert('位置情報の利用が許可されていません');
            break;
        case 2: //POSITION_UNAVAILABLE
            alert('現在位置が取得できませんでした');
            break;
        case 3: //TIMEOUT
            alert('タイムアウトになりました');
            break;
        default:
            alert('その他のエラー(エラーコード:' + e.code + ')');
            break;
    }
}
