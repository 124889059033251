import React from 'react';
import RedirectToHome from './lib/RedirectToHome';
import CreateSEOTag from './lib/CreateSEOTag';
import GoogleAnalytics from './lib/GoogleAnalytics';
import termsText from '../components/atoms/terms';
import { default as Template } from '../components/templates/Letter';
var Terms = function () { return (React.createElement(React.Fragment, null,
    React.createElement(RedirectToHome, null),
    React.createElement(CreateSEOTag, { title: "\u5229\u7528\u898F\u7D04", description: "furrato\u306E\u5229\u7528\u898F\u7D04\u306E\u30DA\u30FC\u30B8\u3067\u3059\u3002" }),
    React.createElement(GoogleAnalytics, null),
    React.createElement(Template, { title: '利用規約', text: termsText }))); };
export default Terms;
