import React from 'react';
import NormalLayout from './layouts/containers/NormalLayout';
import './Letter.sass';
import './Letter.media.sass';
var Letter = function (props) { return (React.createElement(NormalLayout, null,
    React.createElement("div", { className: "Letter" },
        React.createElement("h2", null, props.title),
        React.createElement("div", { dangerouslySetInnerHTML: {
                __html: props.text
            } })))); };
export default Letter;
