import React, { useEffect } from 'react';
var CreateSEOTag = function (props) {
    useEffect(function () {
        var isAppView = (location.search.indexOf('mode=app') > -1);
        var canonical = document.getElementsByName('canonical')[0];
        var description = document.getElementsByName('description')[0];
        var robots = document.getElementsByName('robots')[0];
        var beforeTitle = document.title;
        var beforeDescription = description.content;
        /* rewrite */
        canonical.href = location.href;
        document.title = props.title || document.title;
        description.content = props.description || description.content;
        if (props.noIndex || isAppView) {
            robots.content = 'noindex';
        }
        return function cleanUp() {
            document.title = beforeTitle;
            description.content = beforeDescription;
            robots.content = isAppView ? 'noindex' : 'index';
        };
    }, []);
    return (React.createElement(React.Fragment, null));
};
export default CreateSEOTag;
