var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FavoriteIcon from '../atoms/bottom-nav-icon/heart.png';
import ClickedFavoriteIcon from '../atoms/bottom-nav-icon/clicked-heart.png';
import GpsIcon from '../atoms/bottom-nav-icon/gps.png';
import ClickedGpsIcon from '../atoms/bottom-nav-icon/clicked-gps.png';
import FurratoIcon from '../atoms/bottom-nav-icon/clicked-furrato-icon.png';
import ClickedFurratoIcon from '../atoms/bottom-nav-icon/furrato-icon.png';
import BottomNavIcon from '../atoms/BottomNavIcon';
import './BottomNav.sass';
import './BottomNav.media.sass';
var BottomNav = function () {
    var _a = __read(useState(true), 2), visible = _a[0], setVisible = _a[1];
    var isAppView = (location.search.indexOf('mode=app') > -1);
    useEffect(function () {
        var scrollTop = 0;
        var toggleVisibleWhenScroll = function () {
            var _a;
            var newScrollTop = (_a = window.scrollY, (_a !== null && _a !== void 0 ? _a : document.documentElement.scrollTop));
            var diff = scrollTop - newScrollTop;
            var sign = (Math.sign(diff) === -1);
            if (Math.abs(diff) < 100 && sign)
                return;
            setVisible(sign ? false : true);
            scrollTop = newScrollTop;
        };
        window.addEventListener('scroll', toggleVisibleWhenScroll);
        return function cleanUp() {
            window.removeEventListener('scroll', toggleVisibleWhenScroll);
        };
    }, []);
    var icons = [
        {
            path: '/',
            normal: FurratoIcon,
            clicked: ClickedFurratoIcon,
        },
        {
            path: '/location',
            normal: GpsIcon,
            clicked: ClickedGpsIcon,
        },
        {
            path: '/heart',
            normal: FavoriteIcon,
            clicked: ClickedFavoriteIcon,
        }
    ];
    return (React.createElement("nav", { className: visible ? 'BottomNav' : 'BottomNav BottomNav--hidden' }, icons.map(function (v) { return (React.createElement("div", { className: "BottomNav__icon", key: v.path },
        React.createElement(Link, { to: isAppView ? v.path + "?mode=app" : v.path },
            React.createElement(BottomNavIcon, { image: v.path === location.pathname ? v.clicked : v.normal })))); })));
};
export default BottomNav;
