/*
  difference to App is hover effect of pictures.
*/
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import Gallery from 'react-stack-gallery';
import NormalLayout from './layouts/containers/NormalLayout';
import MessageWhenContentNone from '../molecules/MessageWhenContentNone';
import Loading from '../organisms/Loading';
import FavoriteItem from '../organisms/FavoriteItem';
import Detail from '../organisms/containers/Detail';
import HoverGalleryPicture from '../molecules/HoverGalleryPicture';
import './Favorite.sass';
import './Favorite.media.sass';
var App = function (props) {
    var setHotelId = props.setHotelId;
    var _a = __read(useState(false), 2), detailVisible = _a[0], setDetailVisible = _a[1];
    /* for hover picture function */
    var _b = __read(useState(undefined), 2), hoverTargetNode = _b[0], setHoverTargetNode = _b[1];
    return (React.createElement(NormalLayout, null,
        React.createElement(MessageWhenContentNone, { visible: props.isContentNone }),
        React.createElement(HoverGalleryPicture, { targetNode: hoverTargetNode }),
        React.createElement("div", { className: "Favorite" },
            React.createElement(Gallery, { lines: window.innerWidth < 1025 ? 1 : 3, screen: window.innerWidth < 1025 ? window.innerWidth : window.innerWidth * 0.82, marginPerc: window.innerWidth ? 0.04 : 0.02, square: window.innerWidth < 1025 ? false : true }, props.hotels.map(function (v) { return (React.createElement(FavoriteItem, { hotelName: v.name, picture: v.image, onClick: function (e) {
                    var elm = e.target;
                    setHoverTargetNode(elm);
                    setTimeout(function () {
                        setHotelId(v.id);
                        setDetailVisible(true);
                    }, 200);
                }, key: v.image })); })),
            React.createElement(Loading, { visible: props.isLoading })),
        React.createElement(Detail, { visible: detailVisible, setVisible: setDetailVisible, onLoadingFinished: function () { return setHoverTargetNode(undefined); }, onClose: function () { return setHoverTargetNode(undefined); } })));
};
export default App;
