import React, { useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import './SideMenu.sass';
import './SideMenu.media.sass';
var SideMenu = function (props) {
    var isAppView = (location.search.indexOf('mode=app') > -1);
    var ref = createRef();
    useEffect(function () {
        if (ref.current && props.getWidth) {
            props.getWidth(ref.current.clientWidth);
        }
    });
    return (React.createElement("aside", { className: props.isVisiblePlayStoreLink
            ? props.visible ? 'SideMenu SideMenu--showPlayStoreLink' : 'SideMenu SideMenu--showPlayStoreLink SideMenu--hidden'
            : props.visible ? 'SideMenu' : 'SideMenu SideMenu--hidden', ref: ref },
        React.createElement("div", { className: props.visible
                ? "SideMenu__inline"
                : "SideMenu__inline--hidden" },
            React.createElement(Link, { className: "SideMenu__textLink", to: isAppView ? "/terms?mode=app" : "/terms" }, "\u5229\u7528\u898F\u7D04"),
            React.createElement(Link, { className: "SideMenu__textLink", to: isAppView ? "/policy?mode=app" : "/policy" }, "\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC"))));
};
export default SideMenu;
