import React from 'react';
import './GalleryImage.sass';
import './GalleryImage.media.sass';
var GalleryImage = function (props) {
    var _a;
    var image = props.image, caption = props.caption;
    if (caption.length > 30) {
        caption = caption.slice(0, 30) + "...";
    }
    else if (caption.length < 10) {
        caption = '';
    }
    function showImage(e) {
        e.target.parentNode.style.opacity = 1;
    }
    return (React.createElement("div", { className: "GalleryImage", onClick: ((_a = props) === null || _a === void 0 ? void 0 : _a.onClick) && props.onClick },
        React.createElement("img", { src: image, style: caption.length === 0 ? { marginBottom: 0 } : {}, onLoad: showImage }),
        React.createElement("div", { className: "GalleryImage__caption" }, caption)));
};
export default GalleryImage;
