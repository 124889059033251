var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import React, { useState, useEffect, useRef } from 'react';
import lodash from 'lodash';
import axios from '../common/axios';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { action as FavoriteAction } from '../modules/favorite';
import { action as HotelIdAction } from '../modules/hotelId';
import CreateSEOTag from './lib/CreateSEOTag';
import GoogleAnalytics from './lib/GoogleAnalytics';
import RedirectToHome from './lib/RedirectToHome';
import initHotelsInformation from './lib/initHotelInformation';
import { default as Template } from '../components/templates/Favorite';
import { default as MobileTemplate } from '../components/templates/MobileFavorite';
var Favorite = function () {
    var dispatch = useDispatch();
    var store = {
        favorite: useSelector(function (state) { return state.favorite; }),
        dispatch: {
            favorite: bindActionCreators(FavoriteAction, dispatch)
        }
    };
    /*
      store hotels information.
      'hotelsState' not work when loop in 'useEffect()'.
    */
    var hotels = useRef([]);
    var _a = __read(useState([]), 2), hotelsState = _a[0], setHotelsState = _a[1];
    function hotelsInit() {
        hotels.current = [];
        setHotelsState([]);
    }
    function setHotels(newHotels) {
        hotels.current = __spread(hotels.current, newHotels);
        setHotelsState(hotels.current);
    }
    /* if can display content none, show message.   */
    var _b = __read(useState(false), 2), isContentNone = _b[0], setIsContentNone = _b[1];
    /* switch Loading */
    var _c = __read(useState(true), 2), isLoading = _c[0], setIsLoading = _c[1];
    /* main processing */
    useEffect(function () {
        hotelsInit(); // init
        var favorites = lodash.chunk(store.favorite, 10);
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var favorites_1, favorites_1_1, v, e_1_1;
            var e_1, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, 6, 7]);
                        favorites_1 = __values(favorites), favorites_1_1 = favorites_1.next();
                        _b.label = 1;
                    case 1:
                        if (!!favorites_1_1.done) return [3 /*break*/, 4];
                        v = favorites_1_1.value;
                        return [4 /*yield*/, axios.get(process.env.API, {
                                params: {
                                    h_id: v,
                                    picts: 5,
                                    pict_size: 5,
                                    xml_ptn: 2
                                }
                            }).then(function (r) {
                                var _a;
                                var jalanData = r.data;
                                if ((_a = jalanData) === null || _a === void 0 ? void 0 : _a.Results) {
                                    setHotels(initHotelsInformation(jalanData.Results));
                                    setIsLoading(false);
                                }
                            }).catch(Boolean)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        favorites_1_1 = favorites_1.next();
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 7];
                    case 6:
                        try {
                            if (favorites_1_1 && !favorites_1_1.done && (_a = favorites_1.return)) _a.call(favorites_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 7:
                        setIsContentNone(hotels.current.length === 0);
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    /* remove display item when cancellation favorite */
    useEffect(function () {
        hotels.current = hotels.current.filter(function (v) { return store.favorite.indexOf(v.id) > -1; });
        setHotelsState(hotels.current);
    }, [store.favorite]);
    var props = {
        hotels: hotelsState,
        setHotelId: bindActionCreators(HotelIdAction.set, dispatch),
        isContentNone: isContentNone,
        isLoading: isLoading
    };
    var T = window.innerWidth < 1025 ? MobileTemplate : Template;
    return (React.createElement(React.Fragment, null,
        React.createElement(RedirectToHome, null),
        React.createElement(CreateSEOTag, { title: "\u304A\u6C17\u306B\u5165\u308A", description: "\u898B\u3064\u3051\u305F\u597D\u307F\u306E\u65C5\u9928\u3084\u30DB\u30C6\u30EB\u3092\u304A\u6C17\u306B\u5165\u308A\u3057\u3066\u3001\u3044\u3064\u3067\u3082\u95B2\u89A7\u3067\u304D\u308B\u3088\u3046\u306B\u3057\u307E\u3057\u3087\u3046\u3002" }),
        React.createElement(GoogleAnalytics, null),
        React.createElement(T, __assign({}, props))));
};
export default Favorite;
