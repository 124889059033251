import React from 'react';
import PlayStoreAppIcon from '../atoms/play-store-app-icon.png';
import PlayStoreBadgeIcon from '../atoms/play-store-badge-icon.png';
import './PlayStoreLink.sass';
import './PlayStoreLink.media.sass';
var PlayStoreLink = function (props) { return (React.createElement("div", { className: props.visible ? "PlayStoreLink" : "PlayStoreLink PlayStoreLink--hidden" },
    React.createElement("div", { className: "PlayStoreLink__closeIcon", onClick: props.onClose }),
    React.createElement("img", { className: "PlayStoreLink__appIcon", src: PlayStoreAppIcon }),
    React.createElement("div", { className: "PlayStoreLink__message" },
        "\u30A2\u30D7\u30EA\u3092\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3057\u3066",
        React.createElement("br", null),
        "\u6C17\u8EFD\u306B\u65C5\u9928\u3084\u30DB\u30C6\u30EB\u3092\u4E0B\u8ABF\u3079",
        React.createElement("div", { className: "PlayStoreLink__messageCaption" }, "\u4ECA\u591C\u6CCA\u307E\u308B\u8FD1\u304F\u306E\u30DB\u30C6\u30EB\u3082\u3059\u3050\u898B\u308C\u308B")),
    React.createElement("a", { className: "PlayStoreLink__badge", target: "_blank", href: "https://play.google.com/store/apps/details?id=com.hktysk.furrato" },
        React.createElement("img", { src: PlayStoreBadgeIcon })))); };
export default PlayStoreLink;
