var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import lodash from 'lodash';
export var init = [];
export var FavoriteAction = {
    ADD: 'FAVORITE_ADD',
    REMOVE: 'FAVORITE_REMOVE'
};
export default function reducer(state, action) {
    if (state === void 0) { state = lodash.cloneDeep(init); }
    switch (action.type) {
        case FavoriteAction.ADD:
            return __spread([action.payload], state);
        case FavoriteAction.REMOVE:
            return state.filter(function (id) { return id !== action.payload; });
        default:
            return state;
    }
}
export var action;
(function (action) {
    action.add = function (hotelId) { return ({
        type: FavoriteAction.ADD,
        payload: hotelId
    }); };
    action.remove = function (hotelId) { return ({
        type: FavoriteAction.REMOVE,
        payload: hotelId
    }); };
})(action || (action = {}));
