var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect, useRef } from 'react';
import './DetailHeader.sass';
import './DetailHeader.media.sass';
var DetailHeader = function (props) {
    var className = {
        normal: 'DetailHeader',
        hidden: 'DetailHeader DetailHeader--hidden',
        translate: 'DetailHeader DetailHeader--translate'
    };
    var _a = __read(useState(className.hidden), 2), detailHeaderClassName = _a[0], setDetailHeaderClassName = _a[1];
    var isFirstLoad = useRef(true);
    useEffect(function () {
        if (isFirstLoad.current)
            return;
        if (props.visible) {
            setDetailHeaderClassName(className.normal);
        }
        else {
            setDetailHeaderClassName(className.translate);
            setTimeout(function () { return setDetailHeaderClassName(className.hidden); }, 300);
        }
    }, [props.visible]);
    useEffect(function () {
        isFirstLoad.current = false;
    }, []);
    return (React.createElement("aside", { className: detailHeaderClassName },
        React.createElement("div", { className: "DetailHeader__inline" },
            "\u8A73\u7D30",
            React.createElement("div", { className: "DetailHeader__closeIcon" }),
            React.createElement("div", { className: "DetailHeader__closeSpace", onClick: props.onClose }))));
};
export default DetailHeader;
