import React from 'react';
import ShadowCatIcon from '../atoms/shadow-cat.png';
import './MessageWhenContentNone.sass';
import './MessageWhenContentNone.media.sass';
var MessageWhenContentNone = function (props) { return (React.createElement("div", { className: "MessageWhenContentNone", style: {
        display: props.visible ? 'block' : 'none'
    } },
    React.createElement("img", { src: ShadowCatIcon }),
    "\u8868\u793A\u3067\u304D\u308B\u30B3\u30F3\u30C6\u30F3\u30C4\u304C\u3042\u308A\u307E\u305B\u3093\u3002")); };
export default MessageWhenContentNone;
