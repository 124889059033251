import React from 'react';
import './FavoriteIcon.sass';
var FavoriteIcon = function (props) {
    var _a;
    return (React.createElement("div", { className: props.selected === false
            ? 'FavoriteIcon'
            : 'FavoriteIcon FavoriteIcon--selected', style: {
            transform: "scale(" + props.parentWidth / 100 + ")"
        }, onClick: ((_a = props) === null || _a === void 0 ? void 0 : _a.onClick) && props.onClick }));
};
export default FavoriteIcon;
