import lodash from 'lodash';
export var init = 'on';
export var PlayStoreLinkAction = {
    HIDE: 'PLAYSTORELINK_HIDE'
};
export default function reducer(state, action) {
    if (state === void 0) { state = lodash.cloneDeep(init); }
    switch (action.type) {
        case PlayStoreLinkAction.HIDE:
            return action.payload;
        default:
            return state;
    }
}
export var action;
(function (action) {
    action.hide = function () { return ({
        type: PlayStoreLinkAction.HIDE,
        payload: 'off'
    }); };
})(action || (action = {}));
