function reverseString(str) {
    return str
        .split('')
        .reverse()
        .join('');
}
function formatRate(number) {
    number = reverseString("" + number);
    number = reverseString(number.replace(/.{3}/g, function (arg) { return arg + ","; }));
    if (number.slice(0, 1) === ',')
        number = number.slice(1);
    return number;
}
export default formatRate;
