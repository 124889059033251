var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useState, useEffect, useRef } from 'react';
import axios from '../common/axios';
import lodash from 'lodash';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { action as refineAction } from '../modules/refine';
import { action as hotelIdAction } from '../modules/hotelId';
import CreateSEOTag from './lib/CreateSEOTag';
import GoogleAnalytics from './lib/GoogleAnalytics';
import RedirectToHome from './lib/RedirectToHome';
import initHotelsInformation from './lib/initHotelInformation';
import converToJaranAPICoords, { getGPSError } from './lib/getGPS';
import { default as Template } from '../components/templates/App';
import { default as MobileTemplate } from '../components/templates/MobileApp';
var App = function () {
    var dispatch = useDispatch();
    var store = {
        refine: useSelector(function (state) { return state.refine; }),
        dispatch: {
            refine: bindActionCreators(refineAction, dispatch)
        }
    };
    /* if can display content none, show message.   */
    var _a = __read(useState(false), 2), isContentNone = _a[0], setIsContentNone = _a[1];
    /* switch Loading */
    var _b = __read(useState(true), 2), isLoading = _b[0], setIsLoading = _b[1];
    /*
      store hotels information.
      'hotelsState' not work when loop in 'useEffect()'.
    */
    var hotels = useRef([]);
    var _c = __read(useState([]), 2), hotelsState = _c[0], setHotelsState = _c[1];
    function hotelsInit() {
        hotels.current = [];
        setHotelsState([]);
    }
    function setHotels(newHotels) {
        hotels.current = __spread(hotels.current, newHotels);
        setHotelsState(hotels.current);
    }
    /* fire flag */
    var _d = __read(useState(false), 2), isConnectToAPI = _d[0], setIsConnectToAPI = _d[1];
    /* stop get from jalan */
    var isStopConnectToAPI = useRef(false);
    /* save start number for get from api */
    var startCounter = useRef(1);
    /* initialize */
    useEffect(function () {
        hotelsInit();
        startCounter.current = 1;
        isStopConnectToAPI.current = false;
        setIsContentNone(false);
        setIsConnectToAPI(true);
    }, [store.refine]);
    /* infinite scroll */
    useEffect(function () {
        var infiniteScroll = function () {
            var _a;
            if (isConnectToAPI === true || isStopConnectToAPI.current === true)
                return;
            var reloadPoint = document.body.scrollHeight - window.innerHeight;
            var scrollTop = (_a = window.scrollY, (_a !== null && _a !== void 0 ? _a : document.documentElement.scrollTop));
            if (scrollTop + window.innerHeight > reloadPoint) {
                setIsConnectToAPI(true);
            }
        };
        window.addEventListener('scroll', infiniteScroll);
        return function cleanUp() {
            window.removeEventListener('scroll', infiniteScroll);
        };
    }, []);
    /*
      main processing.
      get hotel information on base of location information
    */
    useEffect(function () {
        if (isConnectToAPI === false)
            return;
        var mounted = true;
        setIsLoading(true);
        var geolocation = window.navigator.geolocation;
        geolocation.getCurrentPosition(function (position) {
            var _a = converToJaranAPICoords(position.coords), latitude = _a.latitude, longitude = _a.longitude;
            (function () { return __awaiter(void 0, void 0, void 0, function () {
                var params;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = lodash.cloneDeep(store.refine.params);
                            delete params.pref;
                            params = __assign(__assign({}, params), { x: longitude, y: latitude, range: 5, start: startCounter.current });
                            return [4 /*yield*/, axios.get(process.env.API, {
                                    params: params
                                }).then(function (r) {
                                    var _a, _b, _c;
                                    if (mounted === false)
                                        return;
                                    var jalanData = r.data;
                                    if ((_a = jalanData) === null || _a === void 0 ? void 0 : _a.Results) {
                                        setHotels(initHotelsInformation(jalanData.Results));
                                        startCounter.current += store.refine.params.count;
                                    }
                                    if (((_b = jalanData) === null || _b === void 0 ? void 0 : _b.Results) === undefined && ((_c = jalanData) === null || _c === void 0 ? void 0 : _c[0]) === undefined) {
                                        isStopConnectToAPI.current = true;
                                    }
                                }).catch(Boolean)];
                        case 1:
                            _a.sent();
                            setIsConnectToAPI(false);
                            setIsContentNone(hotels.current.length === 0);
                            setIsLoading(false);
                            return [2 /*return*/];
                    }
                });
            }); })();
        }, function (error) {
            setIsContentNone(hotels.current.length === 0);
            isStopConnectToAPI.current = true;
            setIsLoading(false);
            getGPSError(error);
        });
        return function cleanUp() {
            mounted = false;
        };
    }, [isConnectToAPI]);
    var props = {
        hotels: hotelsState,
        setHotelId: bindActionCreators(hotelIdAction.set, dispatch),
        isContentNone: isContentNone,
        isLoading: isLoading
    };
    var T = window.innerWidth < 1025 ? MobileTemplate : Template;
    return (React.createElement(React.Fragment, null,
        React.createElement(RedirectToHome, { isLocation: true }),
        React.createElement(CreateSEOTag, { title: "\u73FE\u5728\u5730\u304B\u30895km\u570F\u5185\u3092\u691C\u7D22", description: "\u73FE\u5728\u5730\u304B\u30895km\u570F\u5185\u306E\u65C5\u9928\u3084\u30DB\u30C6\u30EB\u3001\u30B3\u30F3\u30C9\u30DF\u30CB\u30A2\u30E0\u3068\u3044\u3063\u305F\u65BD\u8A2D\u306E\u5199\u771F\u3092SNS\u306E\u3088\u3046\u306B\u95B2\u89A7\u3067\u304D\u307E\u3059\u3002" }),
        React.createElement(GoogleAnalytics, null),
        React.createElement(T, __assign({}, props))));
};
export default App;
