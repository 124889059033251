var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect, createRef } from 'react';
import ColorStarIcon from '../atoms/color-star.png';
import GrayStarIcon from '../atoms/gray-star.png';
import FavoriteIcon from '../molecules/FavoriteIcon';
import './Stars.sass';
import './Stars.media.sass';
var Stars = function (props) {
    var rating = props.rating;
    var starIcon = createRef();
    var _a = __read(useState(0), 2), starWidth = _a[0], setStarWidth = _a[1];
    var favoriteIcon = createRef();
    var _b = __read(useState(0), 2), favoriteIconWidth = _b[0], setFavoriteIconWidth = _b[1];
    useEffect(function () {
        var _a, _b, _c, _d;
        setStarWidth(((_b = (_a = starIcon) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.clientWidth) || 0);
        setFavoriteIconWidth(((_d = (_c = favoriteIcon) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.clientWidth) || 0);
    }, [starIcon]);
    return (React.createElement("div", { className: "Stars" },
        Array(5).fill(null).map(function (_, k) { return (React.createElement("img", { src: rating ? ColorStarIcon : GrayStarIcon, className: "Stars__icon", ref: starIcon, key: k })); }),
        React.createElement("div", { className: "Stars__eraseBox", style: {
                left: (rating ? starWidth * Number(rating) : 0) + "px",
                opacity: rating ? 1 : 0
            } }),
        React.createElement("div", { className: "Stars__favoriteIcon", ref: favoriteIcon },
            React.createElement(FavoriteIcon, { parentWidth: favoriteIconWidth, selected: props.favoriteSelected, onClick: props.toggleFavorite }))));
};
export default Stars;
