var refine = {
    pref: {
        '全国': '000000',
        '北海道': '010000',
        '青森': '020000',
        '岩手': '030000',
        '宮城': '040000',
        '秋田': '050000',
        '山形': '060000',
        '福島': '070000',
        '栃木': '080000',
        '群馬': '090000',
        '茨城': '100000',
        '埼玉': '110000',
        '千葉': '120000',
        '東京都': '130000',
        '神奈川': '140000',
        '山梨': '150000',
        '長野': '160000',
        '新潟': '170000',
        '富山': '180000',
        '石川': '190000',
        '福井': '200000',
        '静岡': '210000',
        '岐阜': '220000',
        '愛知': '230000',
        '三重': '240000',
        '滋賀': '250000',
        '京都府': '260000',
        '大阪府': '270000',
        '兵庫': '280000',
        '奈良': '290000',
        '和歌山': '300000',
        '鳥取': '310000',
        '島根': '320000',
        '岡山': '330000',
        '広島': '340000',
        '山口': '350000',
        '徳島': '360000',
        '香川': '370000',
        '愛媛': '380000',
        '高知': '390000',
        '福岡': '400000',
        '佐賀': '410000',
        '長崎': '420000',
        '熊本': '430000',
        '大分': '440000',
        '宮崎': '450000',
        '鹿児島': '460000',
        '沖縄': '470000'
    },
    type: {
        '旅館': 1,
        'ホテル・ビジネスホテル': 4,
        'ペンション・民宿・ロッジ': 2,
        '貸し別荘・コンドミニアム': 3,
        '公共の宿': 5
    },
    other: {
        '屋外プール': 'o_pool',
        '駐車場': 'parking',
        '内湯・大浴場': 'pub_bath',
        '温泉': 'onsen',
        '貸切風呂': 'prv_bath',
        'サウナ': 'sauna',
        'ジャグジー': 'jacz',
        'マッサージ': 'mssg',
        '貸しスキー': 'r_ski',
        '貸しボード': 'r_brd',
        'ペットOK': 'pet',
        'エステ設備': 'esthe',
        '卓球': 'p_pong',
        '送迎': 'limo',
        '部屋で朝食': 'room_b',
        '部屋で夕食': 'room_d',
        '個室で朝食': 'prv_b',
        '個室で夕食': 'prv_d',
        'チェックイン14時以降': 'early_in',
        'チェックアウト11時以降': 'late_out',
        '禁煙': 'no_smk',
        'インターネット': 'net',
        '露天風呂付き': 'r_room',
        'ハイクラス': 'high',
        'ポイント割引': 'p_ok',
        '特別室・離れ・スイート': 'sp_room',
        'バス・トイレ付き': 'bath_to',
        '露天風呂': 'o_bath',
        '天然温泉かけ流し': 'pour',
        'にごり湯': 'cloudy',
        '屋内プール': 'l_pool',
        'フィットネスジム': 'fitness',
        '体育館': 'gym',
        'グラウンド': 'p_field',
        'バーベキュー施設': 'bbq',
        '宴会場': 'hall',
        '駅から徒歩5分': '5_station',
        'ビーチから徒歩5分': '5_beach',
        'ゲレンデから徒歩5分': '5_slope',
        'クレジットカード可': 'c_card',
        'コンビニまで徒歩5分': 'cvs',
        '食事なしプランあり': 'no_meal',
        '朝食のみプランあり': 'b_only',
        '夕食のみプランあり': 'd_only',
        '朝夕食付きプランあり': '2_meals',
        'シングルルーム': 'sng_room',
        'ツインルーム': 'twn_room',
        'ダブルルーム': 'dbl_room',
        'トリプルルーム': 'tri_room',
        '4ベッドルーム': '4bed_room',
        '和室': 'jpn_room',
        '和洋室': 'j_w_room',
        '子ども料金あり': 'child_price',
        '小学生料金あり': 'c_sc',
        '幼児向けに布団・食事あり': 'c_bed_meal'
    }
};
export var prefSearchValue = function (key) {
    var keys = Object.keys(refine.pref);
    var values = Object.values(refine.pref);
    return values[keys.indexOf(key)];
};
export var prefSearchKey = function (value) {
    var keys = Object.keys(refine.pref);
    var values = Object.values(refine.pref);
    return keys[values.indexOf(value)];
};
/*
stay_count
room_count
adult_num
sc_num
lc_num_bed_meal
min_rate
max_rate
*/
export default refine;
