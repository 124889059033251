import React from 'react';
import LoadingIcon from '../molecules/LoadingIcon';
import './Loading.sass';
import './Loading.media.sass';
var Loading = function (props) {
    var _a;
    return (React.createElement("div", { className: props.visible ? "Loading" : "Loading Loading--hidden" },
        React.createElement("div", { className: ((_a = props) === null || _a === void 0 ? void 0 : _a.iconClassName) ? props.iconClassName : 'Loading__icon' },
            React.createElement(LoadingIcon, null))));
};
export default Loading;
