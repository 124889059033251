import lodash from 'lodash';
export default function (ary) {
    /*
      repeat shuffle,
      on the basis last digit of seconds in current time.
    */
    var sec = parseInt(new Date().getSeconds().toString().slice(-1));
    for (var i = 0; i < sec; i++) {
        ary = lodash.shuffle(ary);
    }
    return ary;
}
