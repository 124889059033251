var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import axios from '../../../common/axios';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { action as hotelIdAction } from '../../../modules/hotelId';
import { action as favoriteAction } from '../../../modules/favorite';
import initDetailInformation from '../lib/initDetailInformation';
import Detail from '../Detail';
var DetailContainer = function (props) {
    var dispatch = useDispatch();
    var store = {
        hotelId: useSelector(function (state) { return state.hotelId; }),
        favorite: useSelector(function (state) { return state.favorite; }),
        dispatch: {
            hotelId: bindActionCreators(hotelIdAction, dispatch),
            favorite: bindActionCreators(favoriteAction, dispatch)
        }
    };
    var _a = __read(useState(initDetailInformation()), 2), detail = _a[0], setDetail = _a[1];
    var _b = __read(useState(true), 2), loading = _b[0], setLoadingIcon = _b[1];
    var _c = __read(useState(false), 2), favoriteSelected = _c[0], setFavoriteSelected = _c[1];
    /* favorite functions */
    function toggleFavorite() {
        favoriteSelected
            ? store.dispatch.favorite.remove(store.hotelId)
            : store.dispatch.favorite.add(store.hotelId);
        setFavoriteSelected(!favoriteSelected);
    }
    /* base */
    useEffect(function () {
        if (!store.hotelId)
            return;
        var mounted = true;
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var hotel;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        setLoadingIcon(true);
                        return [4 /*yield*/, axios.get(process.env.API, {
                                params: {
                                    h_id: store.hotelId,
                                    picts: 5,
                                    pict_size: 5,
                                    xml_ptn: 2
                                }
                            }).then(function (r) { return r.data; }).catch(Boolean)];
                    case 1:
                        hotel = _c.sent();
                        if (((_a = hotel) === null || _a === void 0 ? void 0 : _a.Results) && mounted === true) {
                            setDetail(initDetailInformation(hotel.Results.Hotel));
                            setLoadingIcon(false);
                            setFavoriteSelected(store.favorite.indexOf(store.hotelId) > -1);
                            if (window.innerWidth < 1025)
                                document.body.style.overflow = 'hidden';
                            if ((_b = props) === null || _b === void 0 ? void 0 : _b.onLoadingFinished) {
                                props.onLoadingFinished();
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
        return function cleanUp() {
            mounted = false;
        };
    }, [store.hotelId]);
    function onClose() {
        if (window.innerWidth < 1025)
            document.body.style.overflow = 'auto';
        props.onClose && props.onClose();
        setTimeout(function () {
            store.dispatch.hotelId.set('');
            setDetail(initDetailInformation());
        }, 500);
    }
    return (React.createElement(Detail, __assign({ detail: detail, loading: loading, favoriteSelected: favoriteSelected, toggleFavorite: toggleFavorite }, props, { onClose: onClose })));
};
export default DetailContainer;
