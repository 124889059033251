import { combineReducers } from 'redux';
import refine from './refine';
import hotelId from './hotelId';
import favorite from './favorite';
import playStoreLink from './playStoreLink';
var rootReducer = combineReducers({
    refine: refine,
    hotelId: hotelId,
    favorite: favorite,
    playStoreLink: playStoreLink
});
export default rootReducer;
