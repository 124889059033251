import React from 'react';
import StandardCarousel from 'react-standard-carousel';
import formatRate from './lib/formatRate';
import './DetailPlanCarousel.sass';
import './DetailPlanCarousel.media.sass';
var DetailPlanCarousel = function (props) { return (React.createElement("a", { href: props.url, target: "_blank", className: "DetailPlanCarousel" },
    React.createElement("div", { className: "DetailPlanCarousel__rate" },
        "\u00A5",
        formatRate(props.rate),
        " \u301C"),
    React.createElement("div", { className: "DetailPlanCarousel__carousel" }, (function () {
        if (props.images.length === 0) {
            return (React.createElement("div", { className: "DetailPlanCarousel___nonePicture" }));
        }
        var planPictureJSX = props.images.map(function (v) { return (React.createElement("div", { className: "DetailPlanCarousel__slide", style: { backgroundImage: "url(" + v + ")" }, key: v })); });
        var carousel = (React.createElement(StandardCarousel, { arrow: false, dotBorderWidth: 1 }, planPictureJSX));
        return planPictureJSX.length === 1 ? planPictureJSX : carousel;
    })()),
    React.createElement("div", { className: "DetailPlanCarousel__planName" }, props.name))); };
export default DetailPlanCarousel;
