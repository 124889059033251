import React from 'react';
import RefineTypes from './RefineTypes';
import RefineOptions from './RefineOptions';
import refineMap from '../atoms/Refine';
import './Refine.sass';
import './Refine.media.sass';
var Refine = function (props) {
    var visible = props.visible, store = props.store;
    return (React.createElement("div", { className: visible ? 'Refine' : 'Refine Refine--hidden' },
        React.createElement("div", { className: "Refine__inline" },
            React.createElement("div", { className: "Refine__half--types" },
                React.createElement(RefineTypes, { h_type: store.refine.params.h_type, onClick: function (newType) {
                        store.dispatch.refine.set('h_type', refineMap.type[newType]);
                    } })),
            React.createElement("div", { className: "Refine__half--options" },
                React.createElement(RefineOptions, { refine: store.refine, onClick: function (isAlready, key) {
                        isAlready
                            ? store.dispatch.refine.remove(key)
                            : store.dispatch.refine.set(key, 1);
                    } })))));
};
export default Refine;
