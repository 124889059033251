import React from 'react';
import refineMap from '../atoms/Refine';
import SelectCatIcon from '../atoms/select-cat.png';
import NoneSelectCatIcon from '../atoms/none-select-cat.png';
import './RefineOptions.sass';
import './RefineOptions.media.sass';
var RefineOptions = function (props) {
    var options = Object.keys(refineMap.other);
    options.sort(function (a, b) { return a.length - b.length; });
    var optionsJSX = options.map(function (v) {
        var _a;
        var key = refineMap.other[v];
        var isAlready = (((_a = props.refine.params) === null || _a === void 0 ? void 0 : _a[key]) !== undefined);
        return (React.createElement("div", { className: isAlready
                ? "RefineOptions__btn RefineOptions__btn--selected"
                : "RefineOptions__btn", onClick: function () { return props.onClick(isAlready, key); }, key: v },
            React.createElement("img", { src: isAlready ? SelectCatIcon : NoneSelectCatIcon }),
            v));
    });
    return (React.createElement("div", { className: "RefineOptions" },
        React.createElement("div", { className: "RefineOptions__inline" }, optionsJSX)));
};
export default RefineOptions;
