var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import lodash from 'lodash';
export default function initHotelsInformation(jalanDataResults, isShuffle) {
    var _a, _b;
    if (!((_a = jalanDataResults) === null || _a === void 0 ? void 0 : _a.Hotel))
        return [];
    /* jalanData.Results.Hotel is any or any[] when get from jalan api */
    var hotels = ((_b = jalanDataResults.Hotel) === null || _b === void 0 ? void 0 : _b[0]) ? Array.from(jalanDataResults.Hotel)
        : [jalanDataResults.Hotel];
    /* exclude if not have picture */
    hotels = hotels.filter(function (v) { var _a, _b; return (((_a = v) === null || _a === void 0 ? void 0 : _a.PictureURL) && ((_b = v) === null || _b === void 0 ? void 0 : _b.PictureCaption)); });
    hotels = hotels.map(function (v) {
        if (typeof v.PictureURL === 'string')
            return v;
        /* if image more than 1, random select display images. */
        var randomindexes = lodash.shuffle(__spread(Array(v.PictureURL.length)).map(function (_, k) { return k; }));
        var selectedIdx = randomindexes[0];
        v.PictureURL = v.PictureURL[selectedIdx];
        v.PictureCaption = v.PictureCaption[selectedIdx];
        return v;
    });
    var result = hotels.map(function (v) { return ({
        id: v.HotelID,
        name: v.HotelName,
        image: v.PictureURL,
        caption: v.PictureCaption
    }); });
    return isShuffle ? lodash.shuffle(result) : result;
}
