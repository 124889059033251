var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import PlayStoreLink from '../../organisms/PlayStoreLink';
import Header from '../../organisms/Header';
import SideMenu from '../../organisms/SideMenu';
import BottomNav from '../../organisms/BottomNav';
import './NormalLayout.sass';
import './NormalLayout.media.sass';
var NormalLayout = function (props) {
    var _a = __read(useState(false), 2), sideMenuVisible = _a[0], setSideMenuVisible = _a[1];
    var _b = __read(useState(0), 2), sideMenuWidth = _b[0], setSideMenuWidth = _b[1];
    // initialize
    useEffect(function () {
        window.scrollTo(0, 0);
        // if connect from app or other than Android, hide link card for play store.
        if (location.search.indexOf('mode=app') > -1 || window.navigator.userAgent.indexOf('Android') === -1) {
            props.store.dispatch.playStoreLink.hide();
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(PlayStoreLink, { visible: props.store.playStoreLink === 'on', onClose: props.store.dispatch.playStoreLink.hide }),
        React.createElement(Header, { isVisiblePlayStoreLink: props.store.playStoreLink === 'on', isVisibleSideMenu: sideMenuVisible, clickHamburgerMenu: function () { return setSideMenuVisible(!sideMenuVisible); } }),
        React.createElement("div", { className: props.store.playStoreLink === 'on'
                ? "NormalLayout NormalLayout--showPlayStoreLink"
                : "NormalLayout" },
            React.createElement(SideMenu, { visible: sideMenuVisible, getWidth: setSideMenuWidth, isVisiblePlayStoreLink: props.store.playStoreLink === 'on' }),
            React.createElement("main", { className: "NormalLayout__child", style: { left: sideMenuVisible ? -sideMenuWidth + "px" : 0 } }, React.Children.map(props.children, function (v) { return v; }))),
        React.createElement(BottomNav, null)));
};
export default NormalLayout;
