var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect, createRef } from 'react';
import refineMap, { prefSearchValue } from '../atoms/Refine';
import './PrefNav.sass';
import './PrefNav.media.sass';
var Refine = function (props) {
    var store = props.store;
    var prefs = Object.keys(refineMap.pref);
    var _a = __read(useState(0), 2), preferBoxCoord = _a[0], setPreferBoxCoord = _a[1];
    var _b = __read(useState(Object.values(refineMap.pref).indexOf(store.refine.params.pref)), 2), selected = _b[0], setSelected = _b[1];
    var ref = createRef();
    var _c = __read(useState(0), 2), inlineWidth = _c[0], setInlineWidth = _c[1];
    useEffect(function () {
        setInlineWidth(ref.current ? ref.current.clientWidth : 0);
    }, [ref]);
    function handle(w) {
        var range = 400;
        var increaseLimit = (w === 'increase' && preferBoxCoord + range > inlineWidth);
        var decreaseLimit = (w === 'decrease' && preferBoxCoord === 0);
        if (increaseLimit || decreaseLimit)
            return;
        setPreferBoxCoord(w === 'increase' ? preferBoxCoord + range : preferBoxCoord - range);
    }
    return (React.createElement("div", { className: "PrefNav" },
        React.createElement("div", { className: "PrefNav__box" },
            React.createElement("div", { className: "PrefNav__arrowTriangle--right", onClick: function () { return handle('increase'); } }, "\u25B6"),
            React.createElement("div", { className: "PrefNav__arrowTriangle--left", onClick: function () { return handle('decrease'); } }, "\u25C0"),
            React.createElement("div", { className: "PrefNav__inline", ref: ref, style: { transform: "translateX(-" + preferBoxCoord + "px)" } },
                React.createElement("div", { className: "PrefNav__frame" }, prefs.map(function (v, k) { return (React.createElement("div", { className: k === selected
                        ? "PrefNav__btn PrefNav__btn--selected"
                        : "PrefNav__btn", onClick: function () {
                        setSelected(k);
                        store.dispatch.refine.set('pref', prefSearchValue(v));
                    }, key: v }, v)); }))))));
};
export default Refine;
