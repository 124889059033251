var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../atoms/logo.png';
import GrayRefineIcon from '../atoms/gray-settings.png';
import ColorRefineIcon from '../atoms/color-settings.png';
import Refine from './containers/Refine';
import HamburgerMenu from '../atoms/HamburgerMenu';
import PrefNav from './containers/PrefNav';
import './Header.sass';
import './Header.media.sass';
var Header = function (props) {
    var _a = __read(useState(false), 2), refineSearchVisible = _a[0], setRefineVisible = _a[1];
    var _b = __read(useState(false), 2), isScrollHide = _b[0], setIsScrollHide = _b[1];
    // hide when scroll to bottom
    useEffect(function () {
        var scrollTop = 0;
        var toggleHideWhenScroll = function () {
            var _a;
            var newScrollTop = (_a = window.scrollY, (_a !== null && _a !== void 0 ? _a : document.documentElement.scrollTop));
            var diff = scrollTop - newScrollTop;
            var sign = (Math.sign(diff) === -1);
            if (Math.abs(diff) < 100 && sign)
                return;
            setIsScrollHide(sign ? true : false);
            scrollTop = newScrollTop;
        };
        window.addEventListener('scroll', toggleHideWhenScroll);
        return function cleanUp() {
            window.removeEventListener('scroll', toggleHideWhenScroll);
        };
    }, []);
    return (React.createElement("header", { className: props.isVisiblePlayStoreLink === false
            ? isScrollHide ? "Header Header--scrollHide" : "Header"
            : isScrollHide ? "Header Header--scrollHide Header--showPlayStoreLink" : "Header Header--showPlayStoreLink" },
        React.createElement(Refine, { visible: refineSearchVisible }),
        React.createElement("div", { className: "Header__inline" },
            React.createElement("div", { className: "Header__refineIcon", onClick: function () { return setRefineVisible(!refineSearchVisible); } },
                React.createElement("img", { src: refineSearchVisible ? GrayRefineIcon : ColorRefineIcon })),
            React.createElement(Link, { to: "/", className: "Header__logo" },
                React.createElement("img", { src: Logo })),
            React.createElement("div", { className: "Header__menu", onClick: props.clickHamburgerMenu },
                React.createElement(HamburgerMenu, { isClicked: props.isVisibleSideMenu }))),
        React.createElement("div", { className: "Header__prefNavInline" },
            React.createElement(PrefNav, null))));
};
export default Header;
