import React, { createContext } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App from './pages/App';
import Location from './pages/Location';
import Favorite from './pages/Favorite';
import Terms from './pages/Terms';
import Policy from './pages/Policy';
import NotFound from './pages/NotFound';
ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);
export var ReactGAContext = createContext(ReactGA);
var Router = function () { return (React.createElement(ReactGAContext.Provider, { value: ReactGA },
    React.createElement(BrowserRouter, null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: "/", component: App }),
            React.createElement(Route, { path: "/location", component: Location }),
            React.createElement(Route, { path: "/heart", component: Favorite }),
            React.createElement(Route, { path: "/terms", component: Terms }),
            React.createElement(Route, { path: "/policy", component: Policy }),
            React.createElement(Route, { path: "*", component: NotFound }))))); };
export default Router;
