import React from 'react';
import NormalLayout from './layouts/containers/NormalLayout';
import PaperAirlineIcon from '../atoms/paper-aiplane.png';
import './NotFound.sass';
var NotFound = function () { return (React.createElement(NormalLayout, null,
    React.createElement("div", { className: "NotFound" },
        React.createElement("img", { src: PaperAirlineIcon }),
        "404 NotFound",
        React.createElement("br", null),
        "\u304A\u63A2\u3057\u306E\u30DA\u30FC\u30B8\u306F\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002"))); };
export default NotFound;
