import lodash from 'lodash';
export var init = '';
export var HotelIdAction = {
    SET: 'HOTELID_SET'
};
export default function reducer(state, action) {
    if (state === void 0) { state = lodash.cloneDeep(init); }
    switch (action.type) {
        case HotelIdAction.SET:
            return action.payload;
        default:
            return state;
    }
}
export var action;
(function (action) {
    action.set = function (id) { return ({
        type: HotelIdAction.SET,
        payload: id
    }); };
})(action || (action = {}));
